import React from "react"
import Layout from "../components/Layout"

import {
  FlexContainer,
  InfoContainer,
  TargetaPrecio,
  InfoSnack,
  Divider,
} from "../styles/CursosStyle"
import { Title, Button } from "../styles/Common"
import Onda from "../assets/icons/ondasup.svg"
import Bolanaranja from "../assets/icons/bolanaranja.svg"
import OficialCambridge from "../assets/icons/cambridgeoficial.svg"

const cursos = () => {
  return (
    <>
      <Layout>
        <FlexContainer>
          <Bolanaranja className="bolanaranja" />
          <Onda className="onda" />
          <Title>
            <h2>
              Echa un vistazo a nuestros cursos, precios, horarios e información
              de matrículas
            </h2>
          </Title>
          <Divider rounded info>
            Precios
          </Divider>
          <InfoContainer>
            <TargetaPrecio>
              <p className="alumno">Niños/as</p>
              <div className="junto">
                <p className="precio">49€</p>
                <p className="horas">2h/semana</p>
                <p className="maximo">Máximo 8 alumn@s por grupo</p>
              </div>
              <p className="matricula">38€ matrícula única</p>
            </TargetaPrecio>
            <TargetaPrecio>
              <p className="alumno">Adultos y adolescentes</p>
              <div className="junto">
                <p className="precio">56.5€</p>
                <p className="horas">2.5h/semana</p>
                <p className="maximo">Máximo 8-10 alumn@s por grupo</p>
              </div>
              <p className="matricula">38€ matrícula única</p>
            </TargetaPrecio>
            <TargetaPrecio>
              <p className="alumno">Clases individuales</p>
              <div className="junto">
                <p className="precio">15€</p>
                <p className="horas">1h de clase</p>
              </div>
              <p className="matricula">Sin coste de matrícula</p>
            </TargetaPrecio>
          </InfoContainer>
          {/* <Button rounded info>
            Fecha y horarios de matrículas
          </Button> */}
          <Divider>Fechas y horarios de matrículas (De lunes a viernes)</Divider>
          <InfoContainer style={{ paddingTop: "0px" }} column>
            <Title>
              <h3 style={{ fontSize: "14px", marginTop: "10px" }}>
                Durante el periodo de matrícula se realizan las pruebas de nivel
                a los alumnos
              </h3>
            </Title>
            <InfoSnack>
              <p className="title">Agosto</p>
              <p className="information">del 26 al 31</p>
            </InfoSnack>
            <InfoSnack>
              <p className="title">Septiembre</p>
              <p className="information">del 1 al 3</p>
            </InfoSnack>
           <InfoSnack>
              <p className="title">Mañanas</p>
 <p className="informacion">De lunes a viernes</p>            <p className="information">de 10:30 a 13:30</p>
            </InfoSnack>
            <InfoSnack>
              <p className="title">Tardes</p>
              <p className="information">de 18:00 a 20:00</p>
            </InfoSnack>
          </InfoContainer>
          <Divider>Preparación exámenes Cambridge</Divider>
          <InfoContainer style={{ paddingTop: "0px" }} column>
            <OficialCambridge className="logo"></OficialCambridge>
            <Title>
              <h3
                style={{
                  fontSize: "16px",
                  marginBottom: "10px",
                  whiteSpace: "pre-wrap",
                }}
              >
                Los cursos de preparación de exámenes oficiales son impartidos
                por un profesor especializado en este campo.
              </h3>
              <h3
                style={{
                  fontSize: "16px",
                  marginBottom: "10px",
                  whiteSpace: "pre-wrap",
                }}
              >
                Los alumn@s se presentan a los exámenes al finalizar el curso
                académico.
              </h3>
              <h3
                style={{
                  fontSize: "16px",
                  marginBottom: "10px",
                  whiteSpace: "pre-wrap",
                }}
              ></h3>
              <h3
                style={{
                  fontSize: "16px",
                  marginBottom: "10px",
                  whiteSpace: "pre-wrap",
                }}
              >
                La académica, como centro oficial de preparación, matrícula los
                candidatos directamente y sin coste de gestión.{" "}
              </h3>
              <h3
                style={{
                  fontSize: "16px",
                  marginBottom: "10px",
                  whiteSpace: "pre-wrap",
                }}
              >
                Las ventajas de realizar la matrícula a través del centro LONDON
                SCHOOL OF ENGLISH es que los alumnos se examinarán con sus
                compañer@s y sabrán de antemano con que compañer@ realizarán la
                parte oral el día del examen teniendo la ventaja de practicar en
                pareja esta tarea. De este modo, los candidat@s estarán muy
                preparad@s y más relajados la hora de realizar el exámen oral.
              </h3>
            </Title>
            {/* <InfoSnack>
              <p className="title">Agosto</p>
              <p className="information">del 21 al 24</p>
            </InfoSnack> */}
          </InfoContainer>
          <Divider rounded info>
            Horarios de clase
          </Divider>
          <InfoContainer style={{ paddingTop: "0px" }} column>
            {/* <InfoSnack>                  <p className="title">Atención al cliente</p>
              <p className="information">de 17:00 a 20:00</p>
            </InfoSnack> */}
            <Title></Title>
            <InfoSnack>
              <p className="title">Tardes</p>
              <p className="information">de 15:30 al 21:00</p>
            </InfoSnack>
          </InfoContainer>
        </FlexContainer>
      </Layout>
    </>
  )
}

export default cursos
